<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="soldto">
        <label class="soldto-title">Ingresar a {{title}}</label>
        <img
          class="soldto-cerrar"
          src="../assets/img/icons/close.png"
          @click="cerrar()"
        />
        <div class="soldto-row">
          <div>
            <div class="soldto-label">Sold to
              <label class="soldto-label-error error-label" v-if="errorSoldto">El código ingresado es incorrecto</label>
            </div>
          </div>
          <input
           class="soldto-input"
            type="text"
            placeholder="Escribe código sold to"
            v-model="soldto"
            :class="{ 'error-input': errorSoldto }"
          />
        </div>
        <button class="soldto-btn soldto-btn-cancelar" @click="cerrar()">Cancelar</button>
        <button class="soldto-btn" @click="guardar()">Ingresar</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "soldtopopup",
  components: { },
  props: {
    title: String,
    callback: Function,
    modulo: String
  },
  data() {
    return {
      mostrar: false,
      soldto: "",
      errorSoldto: false,
    };
  },
  created() {},
  mounted() {
  },
  computed: {},
  methods: {
    abrir(){
      this.mostrar = true
    },
    cerrar() {
      this.mostrar = false;
      this.titulo = "";
      this.errorSoldto = false;
    },
    guardar() {
      const that = this;
      if(this.soldto == null || this.soldto == ""){
        this.errorSoldto = true;
        return;
      }
      this.errorSoldto = false;
      this.$axios
        .get(this.$localurl + "/api/usuarios/chequearSoldtoPara/"+ this.modulo +"/" + this.soldto)
        .then(function () {
          localStorage.setItem("soldTo", that.soldto);
          that.cerrar();
          that.callback();
        })
        .catch(function () {
          that.errorSoldto = true;
        });
    },
  },
};
</script>

<style scoped src="../assets/css/components/soldtopopup.css"></style>